
.direction {
    @include direction(column);
    .header {
@include flex(center, center);
    .formFilling {
        .logo {
            img {
                max-width: 100%;
            }
        }
    }
}
.footerButton {
   @include flex(center, center);
}
.formBook {
    border: 1px solid #f1f1f1;
    @include border-radius(5px);
    margin-top: 2rem;
    margin-bottom: 50px;
    background: #ffffff;
    position: relative;
    padding: 50px 30px 50px;
    border-top: 3px solid #15558d;
    z-index: 1;
    overflow: hidden;
}
.MuiFormControl-root {
    width: 100%;
}
.signaturepad{
background: #fff;
border: 1px solid #d9d9d9;
}
.signature-buttons{
    display: flex;
}
}
@media (min-width:$ipad-portrait) and (max-width:$ipad-landscape) {
.hospitalForm {
    margin: 0 auto;
}
}