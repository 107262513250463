body {
  font-size: 16px !important;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 14px;
}
.w100 {
  width: 100%;
}
.landing-page-main {

// .Header{
//   position: fixed;
//     top: 0;
//     max-height: 70px;
//     width: 100vw;

//     display: grid;
//     grid-template-areas: "logo nav";
//     background-color: #282c34;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//     .Logo {
//       grid-area: logo;
//       height: 70px;
//     }
//     .Nav {
//       display: grid;
//       grid-area: nav;
//       grid-template-columns: repeat(4, auto);
//       align-items: center;
//       justify-items: center;
//       a {
//         color: #fff;
//         font-size: 20px;
//         font-weight: 500;
//         transition: 0.5s;
//         text-decoration: none;
//         &:hover {
//           transform: scale(1.1);
//         }
//     }
//     button {
//       padding: 10px;
//       outline: none;
//       border: none;
//       font-size: 20px;
//       color: #fff;
//       font-weight: 600;
//       background-color: rgba(255, 0, 0, 0.5);
//       box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
//       border-radius: 10px;
//       cursor: pointer;
//       transition: 70ms;
//       &:active {
//         transform: translateY(3px);
//         box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
//       }
// }
//     }
//     .Burger {
//       display: none;
//       grid-area: burger;
//       margin: 0 20px 0 0;
//       padding: 0;
//       justify-self: end;
//       font-size: 40px;
//       border: none;
//       background: none;
//       outline: none;
//       transition: 0.1s;
//       &:active {
//         transform: scale(1.2);
//       }
//     }
//   }

//   @media (max-width: 700px) {
//     .Header {
//       grid-template-areas: "logo burger" "nav nav";
//     }
//     .Nav {
//       grid-template-rows: repeat(4, auto);
//       grid-template-columns: none;
//       grid-row-gap: 20px;

//       padding: 30px 0 30px;
//       background: rgba(40, 44, 47, 0.95);
//       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//       border-bottom-left-radius: 10px;
//       border-bottom-right-radius: 10px;
//     }
//     .Burger {
//       display: inline;
//     }
//   }

//   .NavAnimation-enter {
//     opacity: 0;
//     transform: scale(0.5);
//   }
//   .NavAnimation-enter-active {
//     opacity: 1;
//     transform: translateX(0);
//     transition: opacity 350ms, transform 350ms;
//   }
//   .NavAnimation-exit {
//     opacity: 1;
//   }
//   .NavAnimation-exit-active {
//     opacity: 0;
//     transform: scale(0.5);
//     transition: opacity 350ms, transform 350ms;
//   }
.landing-header {
  .Header {
    .topHeader {
      display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 100%;
    .logo {
      img {
      @include breakpoint(xs) {
        width: 75%;
      }
    }
    }
    }
  }
}

.ant-carousel {
  .slick-dots-bottom {
    bottom: -10px;
  }
}




  .text-center {
    @include text-center;
  }
  p {
    font-size: 16px;
  }
  .container {
    width: 100%;
    display: flex;
    align-self: center;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .theme_button {
    background-color: #05d6e7;
    color: #fff;
    align-self: flex-end;
    font-size: 20px;
    min-width: 10rem;
    @include border-radius(5px);
    border-color: #05d6e7;
    box-shadow: none;
  }
  .btn {
    padding: 6px 12px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    @include transit;
    &:focus {
      color: #333;
      outline: none;
    }
    &:active:focus {
      outline: none;
    }
    &:hover {
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: #061138;
      color: $white;
    }
    &:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
  .titleCaption {
    font-size: 50px;
    color: #1a568b;
    font-weight: 600;
    line-height: 1.2;
  }
  // .direction {
  //   @include direction;
  // }
  @include breakpoint(xs) {
    .container {
      flex-direction: column;
    }
  }
  @include breakpoint(sm) {
    .container {
      max-width: 540px;
      // flex-direction: column;
    }
  }
  @include breakpoint(m) {
    .container {
      max-width: 720px;

    }
  }

  @include breakpoint(l) {
    .container {
      max-width: 960px;
    }
  }
  @include breakpoint(xl) {
    .container {
      max-width: 1140px;
    }
  }
  .landing-banner {
    background: url(../LandingPage/assets/images/banner.png) no-repeat top center;
    background-size: cover;
    .doctorPic {
      padding-top: 50px;
      @include breakpoint(xs) {
        padding-top: 0;
        img {
          max-width: 100%;
        }
      }
    }
    .caption {
      min-height: 500px;
      display: flex;
      flex-direction: column;
      //align-items: flex-end;
      justify-content: center;
      @include margin-padding(25px, 0px);
      h1,
      h2 {
        color: $white;
      }
      h1 {
        font-size: 70px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 60px;
      }
      h2 {
        font-weight: 300;
      }
      h3 {
        font-weight: 300;
      }
    }
    @include breakpoint(xs) {
      .mblReverse {
        flex-direction: column-reverse;
        .caption {
          min-height: auto;
          margin: 0;
          padding-top: 50px;
          h1 {
            font-size: 40px;
          }
          h2 {
            font-size: 20px;
          }
          h3 {
            font-size: 18px;
          }
        }
      }
    }

  }


  .landing_pricing {
    background: #f9f9f9;
    padding: 50px 0;
    .pricing_section {
      width: 100%;
      .pricing_head {
        text-align: center;
      }
      .listedPrice {
        .pricinBox {
          background: #fff;
          margin: 20px;
          padding: 35px 20px;
          border-bottom: 5px solid #1a568b;
          box-shadow: rgba(0, 0, 0, 0.10) 0px 1px 4px;
          border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
          .header {
            text-align: center;
            h2 {
              margin-bottom: 0;
              font-weight: 600;
              line-height: 1.2;
              font-size: 20px;
              text-transform: uppercase;
              color: #333;
            }
            .price {
              font-size: 45px;
              font-weight: 600;
              color: #1a568b;
              line-height: 1.3;
              letter-spacing: -2px;
            }
            .sub {
              padding-left: 5px;
              text-transform: uppercase;
              color: #808080;
            }
          }
          .priceBody {
            p {

              @include flex(space-between, center);
              .listing {
                font-size: 1.1rem;
                color: $gray;
                @include transit;
                cursor: pointer;
                &:hover {
                  color: $cyan;
                }
              }
              .pricingActive {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: #bfe3ff;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                  color: $cyan;
                }
              }
              .pricingMinus {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: #ededed;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                  color: #dfdfdf;
                }
            }
            }
          }
        }
      }
    }
  }

  .landing-add {
    .addSection {
      background: url(../LandingPage/assets/images/add.png) no-repeat top center;
      background-attachment: fixed;
      @include margin-padding(0, 50px);
      background-attachment: fixed;
      width: 100%;
      background-size: cover;
      .addCaption {
        @include margin-padding(20px, 50px);
        @include text-center;
        h1 {
          color: $white;
          @include breakpoint(xs) {
            font-size: 28px;
            padding-top: 30px;
          }
        }
        p {
          color: $white;
        }
      }
    }
    @include breakpoint(xs) {
      .addSection {
        @include margin-padding(0, 0px);
      .addCaption {
        @include margin-padding(0 , 0);
      }

      }
    }
  }

  .landing-testimonials {
    .quoteBox {
      .testimonialFooter {
        @include flex(left, center);
        .ant-avatar {
          width: 70px;
          height: 70px;
          line-height: 60px;
          border: 5px solid #f5f5f5;
        }
        .name {
          font-weight: 500;
          @include margin-padding(0, 15px);
          color: #1a568b;
        }

      }
    }
    .ant-carousel {
      .slick-dots {
        li {
          button {
            background-color: #1a568b;
          }
        }
      }
    }
    // .ant-carousel .slick-dots li.slick-active button
    .banner {
      position: relative;
      img {
        width: 85%;
        max-width: 100%;
        position: absolute;
        top: -45px;
        right: 0;
      }
    }
    @include breakpoint(xs) {
      .banner {
        display: none;
      }
    }
  }
.landing-about {
  .aboutSection {
    .about_left {
      padding: 20px;
    }
  }
  @include breakpoint(xs) {
    .about_left {
      display: flex;
      margin-bottom: 20px;
    .circular-square {
      height: 150px;
      margin: 0 auto;
      width: 150px;
    }
  }
  }
}




  .processWrapper {
    background-attachment: fixed;
    background: url(../LandingPage/assets/images/stepsBg.png) no-repeat top center;
    background-size: cover;
    @include margin-padding(0, 50px);
    .container {
        flex-direction: column;
    .heading {
      text-align: center;
      h1 {
        color: $white;
        margin-bottom: 0;
        @include breakpoint(xs) {
          font-size: 28px;
        }
      }
      .lightFont {
        font-weight: 200;
      }
    }
    .stepsAppointment {
      .whiteBox {
        background: #fff;
        @include margin-padding(0 , 20px);
        border-right: 1px solid #e8e8e8;
        position: relative;
        @include transit;
        .stepRound {
          width:50px;
          height: 50px;
          background: #05d6e7;
          @include border-radius(100px);
          @include flex(center , center);
          color: $white;
          font-size: 20px;
          font-weight: 600;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          @include transit;
        }
        .boxStart {
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translate(0px, -50%);
        }
        .iconBox {
            width: 125px;
            height: 125px;
            padding: 5px;
            text-align: center;
            margin: auto;
            @include flex(center , center);
        }
        .footerCaption {
            text-align: center;
            @include margin-padding(0 , 30px);
            @include transit;
            padding-left: 0;
            padding-right: 0;
            h5 {
              color: #717171;
              height: 50px;
            }

        }
        &:hover {
          box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
          .stepRound {
            background: #061138;
            color: $white;
          }
          .footerCaption h5 {
            color: #333;
          }
        }

        }
        .borderOne {
          border-top: 6px solid #bef4ff;
        }
        .borderTwo {
          border-top: 6px solid #60a1ec;
        }
        .borderThree {
          border-top: 6px solid #fc7489;
        }
        .borderFour {
          border-top: 6px solid #ffa661;
        }
        @include breakpoint(xs) {
        .whiteBox {
          margin-bottom: 10px;
        }
      }
      }

    }
    @include breakpoint(xs) {
      padding: 15px;
    }

  }



  .footer {
    background-color: #1a568b;
    @include margin-padding(0 , 50px);
    .contact {
      h3 {
        color: #fff;
      }
      p{
      color: $white;
      }
    }
    .footerInfo {
      display: flex;
        flex-direction: column;
        align-items: flex-end;
      .socilMenu {
        color: #fff;
        span {
        @include margin-padding(2px , 5px);
        }
      }
      .info {
        margin-top: 3.6rem;
      p {
        color: #fff;
        margin-bottom: .5rem;
      }
    }
    }

    }
    @include breakpoint(xs) {
      .footer {
        @include margin-padding(0 , 20px);
      }

    }
@include breakpoint(xs) {
  .titleCaption {
    font-size: 35px;
  }
}

// @include breakpoint(m) {
  @media (max-width:991px) {
    .landing-banner {
      .ipadContainer {
       flex-direction: column-reverse;
       -webkit-flex-direction: column-reverse!important;
      }
  .mblReverse {
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse!important;
    .doctorPic {
      padding-top: 0;
    }
    .caption {
      min-height: auto;
      margin-top: 90px;
    }
  }
}
.landing-testimonials {
  .ant-carousel {
    .slick-dots-bottom {
      bottom: -10px;
    }
  }
}
  }
  @media (min-width:768px) and (max-width:991px) {
.landing-testimonials {
  .banner {
    img {
      top: 172px;
    }
  }
}
}
}

@media (min-width:$ipad-small) and (max-width:$ipad-landscape) {
  .landing-testimonials {
    .banner {
      img {
  top: 38px!important;
}
    }
  }
}







