.dashboard__header {
  background: $primary;
  color: $white;
  // @include margin-padding(0px, 0);
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  padding: 15px 30px;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include fixed;

  @include breakpoint(xs) {
    z-index: 100;
    padding: 15px 15px 15px 30px;
  }
 .title {
   @media(max-width:768px) {
     width: 65%;
   }
 }
 .header-logo {
   width: 250px;
 }
  a {
    text-decoration: none;
    color: white;
  }
  h3 {
    color: $white;
    margin-bottom: 0;
    @include breakpoint(xs) {
      margin-left:40px;
    }
    a {
      color: $white;
      text-decoration: none;
      font-size: 25px;
    @media(max-width:768px) {
        font-size: 17px;
      }
    }
  }
  .logoutBlock a {
    @media(max-width:768px) {
      font-size: 12px;
    }
  }
  
}

@media(min-width:$ipad-portrait) and (max-width:$ipad-small) {
.dashboard__header {
.title {
  margin-left: 50px;
}
}
}



