.dashboard__content {
  // height: 100vh;
  margin-top: 72px;
  background-color: $container-background;
  min-height: 90vh;
  // @include margin-padding(0px, 15px);
  // @include padding-padding(30px 0 0 0);
  padding: 30px 0 0;
  .mblButton {
    border: none;
    position: fixed;
    z-index: 500;
    top: 25px;
    border-radius: 10px;
    background: #16558d;
  }
  .leftblock-sidenav {
    margin-top: -27px;
    overflow :auto;
        height: 100vh;
        position: fixed;
    // padding-left: 30px;
    width: inherit;
    max-width: 400px;
    min-width: 250px;
    @include breakpoint(xs) {
      padding-left: 0;
    }
  }
  .rightblock-content {
    padding: 0 30px;
    @include breakpoint(xs) 
    {
      padding:20px 15px;
    }

  }
}

.dashboard__sidebar {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  // overflow: hidden !important;
  @include breakpoint(l) {
  height: calc(100% - 78px);
    overflow: auto;
  }
  @media(max-width:991px) {
    margin: -24px!important;
  }
.content{
  width: 100%;
  position: relative;
}
  background-color: $white;
  border: 1px solid $gray-light;
  .sidebar_header {
    flex-direction: column;
    @include flex(center, center);
    @include margin-padding(0px, 20px);
    min-width: 200px;
    max-width: 300px;
    border-bottom: 1px solid $gray-light;
   @media(max-width:991px) {
      min-width: 240px;
      max-width: 255px;
    }
    .profileDashboard {
      // @include margin-padding(10px, 8px);
      .sb-avatar,
      .sb-avatar__text {
        width: 85px!important;
        height: 85px!important;
      }
      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        // margin-bottom: 15px;  
      }
      margin-bottom: 0px;
    }
    .hospital_address {
      margin-bottom: 0;
    }
    .hospital_name {
      @include font-size(l);
      color: $gray;
    }
    h5 {
      margin-bottom: 0;
      text-align: center;
    }
  }
  .sidebar_list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 485px; // needs to change when adding sidebar items
    // min-height: 450px;
    // max-height: calc(100vh);
    overflow-y: auto;

    @media(min-height:750px) {
      height: 100%;
    }
    .ant-list-item {
      padding: $padd-0;
      &:hover i {
        color: #15558d
      }
      &:hover a {
        color: #15558d
      }
      .ant-list-item-meta {
        padding: 16px 20px;
        a {
          font-size: 14px;
        }
      }
    }
  }
}
