/* Color name and their code */
$textcolor:#6a6a6a;
$primary: #15558d;
$warning: #ffba00;
$success: #26af48;
$danger: #e63c3d;
$container-background: #f8f9fa;
$bg-white:#fff;
$dodger-blue: #007bff;
$dark-green: #00471c;
$medium-blue: #ac20f4;
$white: #fff; 
$blueDark:#082f50;
$black: #000000;
$blue: #01b3ed;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #ff0000;
$orange: #ffba00;
$yellow: #ffba00;
$green: #74b100;
$teal: #67981a;
$cyan: #0583c5;
$gray: #999999;
$gray-light:#f0f0f0;
$gray-dark: #343a40;
$light: #f0f0f0;
$dark: #000;
$gray: #999999;
$darker-light: #cccccc;
$dark-red: #cc0000; 
$lighter-dark: #262626;
$green: #74b100;
$gray-01: #e8edef;
$gray-02: #f5f5f5;
$gray-03: #eeeeee;
$gray-04: #eef1f2;
$gray-05: #ecf0f1;
$gray-06: #f0f2f3;
$padd-0:0;
$view-button: #038FDF;
$accept-button:#2FAE4D;
$red-button:#E43E42;
$theme-button:#15558d;
$edit-button:#20c0f3;
$bold:600;
$full-width:100%;
$my-color: #000;
$my-opacity: 0.5;
$iphone-portrait: 320px;
$iphone-landscape: 480px;
$ipad-portrait: 768px;
$ipad-small:991px;
$ipad-landscape: 1024px;
$desktop: 1224px;
$desktop-large: 1824px;


/* Difference devices width */
$device_width: (
  xs: 767px,
  // xs: 0,
  sm: 576px,
  m: 768px,
  l: 992px,
  xl: 1200px,
);

/*Related to font */
$default-line-height: 1.5 !default;
$default-font-family: 'Work-Sans';
$default-font-smoothing: subpixel-antialiased !default;
$font-size: (
  xs: 8px,
  sm: 12px,
  m: 16px,
  l: 20px,
  xl: 24px,
  xxl: 28px,
  xxxl: 32px,
);
