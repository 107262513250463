    .mypatient{
        .patient-table {
            margin-top: -60px;
        }
        .search{
            // @include flex(space-between , center);
            padding: 50px 15px 0px 15px;
            // padding-bottom: -20px;
            position: relative;
            top: -60px;
            background: $bg-white;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23);
            // margin-bottom: 30px;
            .search-area {
                display: flex;
               
        }
        .patientButtonform {
            position: relative;
            top: 45px;
        }
                .form-control {
                    margin-right: 10px;
                 
                       
            }
        }
        .ant-modal-title{
            font-size:24px ;
        }
    }
    .dropzone {
        outline: none;
    }

// .thumbimage {
//         max-height: 100px;
//         max-width: 100px;
//         height: 100px;
//         width: 100px;
//         border-radius: 100px;
//     }


    @include breakpoint(xs) {
        .search {
        .ant-space {
    display: block;
    margin-top: 25px;
    .ant-space-item {
    input {
        margin-bottom: 10px;
    }
}
.mbl-button {
    display: flex;
    margin-top: 25px;
}
}
}
    }

