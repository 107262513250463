.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-calendarFilter-button {
  background-color: #edeeee !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  padding: 5px 30px !important;
  margin: 5px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.fc-button-active {
  color: white !important;
  background-color: #038fdf !important;
  border: none !important;
}

.fc-prev-button,
.fc-next-button {
  padding: 5px !important;
}
.fc-prev-button,
.fc-next-button,
.fc-today-button {
  background-color: #f8f9fa !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: black !important;
  text-transform: capitalize !important;
}

.fc-today-button {
  padding: 5px 30px !important;
  background-color: #edeeee !important;
  border-radius: 5px !important;
}
.calendar__modal {
  .ant-modal-body {
    background-color: #fff !important;
    padding: 40px !important;
    border-radius: 5px;
  }
}

.fc-toolbar-title {
  display: inline-block !important;
  margin-right: 10px !important;

  font-size: 18px !important;
}

.fc-header-toolbar {
  width: 100%;
}

.cancelled_appointment {
  &:hover {
    background-color: #edeeee !important;
  }
}

.calendar__newappointment {
  .newappointment__section1 {
    margin-top: 20px;
    font-size: 20px;
    color: black;
    text-align: center;
  }
  .newappointment__section2 {
    .ant-select-arrow {
      svg {
        font-size: 15px;
      }
    }
    display: flex;
    flex-direction: column;
    > div {
      padding: 1px;
      display: flex;
      flex: 1;
      align-items: center;
      p:nth-child(1) {
        display: flex;
        justify-content: flex-end;
        flex: 0.2;
        opacity: 0.7;
        font-size: 12px;
        color: #333;
        font-weight: 500;
        text-align: right;
        min-width: 135px;
        @include breakpoint(xs){
          min-width: 100px;
        }
      }
      p:nth-child(2) {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0.8;
      }
    }
    .MuiInputBase-input {
      @include breakpoint(xs) {
        font-size: 13px;
        text-overflow: ellipsis;
      }
    }
  }
  .newappointment__section3 {
    display: flex;
    flex-direction: column;
    > div {
      padding: 5px;
      display: flex;
      flex: 1;
      align-items: center;
      p:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        flex: 0.3 1;
        opacity: 0.7;
        font-size: 13px;
        color: #333;
        font-weight: 500;
      }
      p:nth-child(2) {
        p {
          background-color: #f5f5f5;
          padding: 5px;
          border-radius: 20px;
          border: 1px solid #e8e8e8;
        }
        display: flex;
        justify-content: flex-start;
        margin-left: 20px;

        flex: 0.75;
        flex-direction: column;
        .ant-select-selector {
          border: none;
          outline: none;
          box-shadow: none;
          background-color:#f5f5f5;
        }
        .ant-select-focused,
        input {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
    .choose-patient {
      margin-top: -20px;
    }
  }
  .popbutton {
    button {
float: right;
    }
  }
}

.provider__filter {
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
  }
}
.calendarSection {
  .header-sub-cont {
    // height: 50px; 
    background: #fff; 
    width: 100%; 
    height: 150px;
    position: relative;
    top: -60px;

    .filterButton  {
      margin-top: 80px;
      margin-right: 20px;
    }
  }
  .calenderBlock {
  margin-top: -65px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  .fc {
    .fc-toolbar {
      @include breakpoint(xs){
      flex-direction: column;
      }
    }
  td {
    font-size: 0.875rem;
    line-height: 3rem;
    padding: 0;
    text-transform: uppercase;
    @include breakpoint(xs) {
      line-height: 1.2rem;
    }
    .fc-day-today {
      background: #f8f9fa;
      .fc-event-main {
        p {
          height: auto !important;
          @include breakpoint(xs) {
            font-size:13px!important;
          }
        }
      }
    }


  }
  }
  .fc .fc-daygrid-event p,
  .fc-event .fc-event-main p {
    height: auto !important;
    font-size: 12px!important;
    white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
  }
  
  }
  .filterButton {
    button {
      float: right;
    }
  }

}
.ant-popover-title {
  min-height: 35px;
  line-height: 35px;
  background: #edeeee;
}

.calendar__newappointment {
  .ant-btn-round {
    border-radius: 5px;
    background: #038fdf !important;
  }
}

.ant-tabs {
  .ant-tabs-tab-btn {
    color: #878b8e !important;
  }
}

.ant-tabs-tab {
  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #15558d !important;
    }
  }
}
.ant-tabs-ink-bar {
  background: #15558d;
}
.fc-h-event {
  border: transparent!important;
}
.fc-media-screen {
.fc-timegrid-event {
  border: transparent;
  background-color: #e6f1fb!important;
}
}

  .fc {
    .fc-daygrid-more-link {
      font-size: 11px;
      @include breakpoint(xs) {
      font-size: 10px;
    }
    }
}
@media (min-width:$ipad-portrait) and (max-width:$ipad-landscape) {
  .fc {
    .fc-toolbar {
      flex-wrap: wrap;
    }
  }
  .calendarMoadl {
    .ant-picker {
    width: 50%;
    .ant-picker-input > input {
      font-size: 13px;
    }
    }
  }
}
.fc-media-screen {
.fc-timegrid-event-harness {
  margin-right: 0 !important; 
  // inset: 918.222px 0% -976.889px 0%!important;
}
}
.fc {
  .fc-timegrid-axis-cushion {
    max-width: 90px!important;
  }
}
@media (max-width:768px) {
  .calendar__modal {
    .ant-modal-body {
      padding: 40px 15px!important;
      .ant-tabs-nav-wrap {
        overflow: visible;
  .ant-tabs-tab {
    margin: 0;
    font-size: 12px;
    overflow: visible;
    .ant-tabs-tab-btn {
      padding: 0 5px!important;
    }
  }
}
}
}
}
@media (min-width:992px) {
  .calendarMoadl {
    .ant-picker {
    width: 50%;
    }
  }
}

.material-search-picker {
  position: relative;
  top: -10px;
}