.the-forms {
    .secondary-head-cont {
       height: 160px;
       width: 100%;
       background-color: #fff;
       position: relative;
       top: -55px; 

       .view-btn {
           width: 100%;
           margin-top: 90px;
           margin-left: 20px;
       }
    }

    .forms-table {
        margin-top: -78px;
    }
}

.responseBox {
    // border-top: 1px solid #dfdfdf;
    padding-top: 20px;
  height: 630px;
  @include breakpoint(xs) {
      height: auto;
  }
    .header {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 18px;
    }
    .body {
        background: #fafafa;
        padding: 15px;
        margin-bottom: 15px;
        p {
            span {
                display: inline-block;
                min-width: 130px;
                font-weight: 600;
                @include breakpoint(xs) {
                    min-width: 105px;
                }
            }
        }
    }
    .responseContent {
        border: 1px solid #e4e4e4;
    .responseBg {
        border-bottom: 1px solid #e4e4e4;
        display: flex;
        .fitstColumn {
            // min-width: 230px;
            width: 230px;
    text-transform: capitalize;
    border-right: 1px solid #e4e4e4;
    padding: 15px;
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
        }
        .question {
            font-weight: 600;
        }
        .secondColumn {
            width: 230px;
            padding: 15px;
            display: flex;
    align-items: center;
    @include breakpoint(xs) {
        padding: 5px;
    }
    p {
        margin-bottom: 0;
    }
        }
    }
}
}
.formtableIcons {
    width: 100%;
    @include flex(flex-end, center);
}