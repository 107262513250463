@mixin flex($justifyContent, $alignItem) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItem;
}

@mixin font-size(
  $size,
  $weight: 400px,
  $font-family: $default-font-family,
  $line-height: $default-line-height
) {
  font-size: map-get($map: $font-size, $key: $size);
  font-family: $font-family;
  font-weight: $weight;
  line-height: $line-height;
}

@mixin breakpoint($type) {
  @if $type==xs {
    @media only screen and (max-width: map-get($device-width,$type)) {
      @content;
    }
  } @else {
    @media only screen and (min-width: map-get($device-width,$type)) {
      @content;
    }
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  z-index: 500;
}
@mixin direction ($direction){
  flex-direction: $direction;
}

@mixin margin-padding($margin, $padding) {
  margin: $margin;
  padding: $padding;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin head {
  font-weight: 600;
  margin-bottom: 20px;
}
@mixin button {
  padding: 15px;
  color: $white;
  border: none;
  box-shadow: none;
  padding: 5px 20px;
  outline: none;
  text-transform: capitalize;
  @include border-radius(4px);
  font-weight: $bold;
  cursor: pointer;
  color: $white;
  @include transit;
}
@mixin plus-button {
  @include button;
  min-width: 3rem;
  background-color: #57beff;
}
@mixin add-button {
  background-color: $theme-button;
  @include button;
}
@mixin edit-button {
  @include button;
  background-color: $edit-button;
  &:hover {
    background-color: #178fb5;
    color: #fff;
  }
  &:active,
  &:focus {
   background: #20c0f3;
   color: #fff;
  }
}
@mixin saveButton {
  @include button;
  background-color: $blueDark;
  color: $white;
  &:active,
  &:focus {
    background-color: $blueDark;
  color: $white;
  }
  &:hover {
    background-color: #095191;
  }
  width: 30%;
  font-size: 18px;
  margin: 35px auto;
}
@mixin btnTransparent {
  background: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  color: #808080;
 margin-left: auto;
 cursor: pointer;
 @include transit;
 &:hover {
   color: #178fb5;
 }
}

@mixin delete-button {
  @include button;
  background-color: $red-button;
  &:hover {
    background-color: #ad2428;
    color: #fff;
  }
  &:active,
  &:focus  {
    background-color: $red-button;
    color: #fff;
  }
}
@mixin view-button {
  @include button;
  background-color: $view-button;
  &:active,
  &:focus  {
    background-color: $view-button;
    color: #fff;
  }
  &:hover {
    background-color: #15558d;
  }
  }
@mixin blueDark-button {
  @include button;
  background-color: $blueDark;
  color: $white;
  &:active,
  &:focus {
    background-color: $blueDark;
  color: $white;
  }
  &:hover {
    background-color: #095191;
  }

}
@mixin common-button {
  @include button;
  background-color: $view-button; 
}
@mixin accept-button {
  @include button;
  background-color: $accept-button;
}

@mixin formInput {
  @include border-radius(5px);
  color: $gray-dark;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.5;
    border: 1px solid #dcdcdc;
    padding: 13px;
    width: 100%;
    outline: none;
    height: 45px;
    min-width: 200px;
}
@mixin selectBox {
  @include border-radius(30px);
  background-color: transparent;
    border: 1px solid #D7D9DC;
    text-align: left;
    text-transform: none;
    color: #464B50;
    padding: 0 1em;
    min-width: 10em;
    outline: none;
}
@mixin text-center {
  text-align: center;
}
@mixin formTo {
  display: inline;
  padding: 0 15px;
}
@mixin circular--square {
  border-radius: 50%;
}

$sizes: 24;

@mixin margin-classes {
  @for $i from 1 through $sizes {
     $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i}  {margin: $margin;}
    .ml#{$i} {margin-left: $margin;}
    .mr#{$i} {margin-right: $margin;}
    .mt#{$i} {margin-top: $margin;}
    .mb#{$i} {margin-bottom: $margin;}
    .mx#{$i} {margin-left: $margin; margin-right: $margin;}
    .my#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;

$sizes: 24;

@mixin padding-classes {
  @for $i from 1 through $sizes {
$padding: $i * 0.25rem;
    .p#{$i}  {padding: $padding;}
    .pl#{$i} {padding-left: $padding;}
    .pr#{$i} {padding-right: $padding;}
    .pt#{$i} {padding-top: $padding;}
    .pb#{$i} {padding-bottom: $padding;}
    .px#{$i} {padding-left: $padding; padding-right: $padding;}
    .py#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

@mixin transit {
 transition: all .4s ease-in-out;
 -moz-transition: all .4s ease-in-out;
 -webkit-transition: all .4s ease-in-out;
}


// copy mediaquery

$media-queries: true;

@mixin bp($point) {
  @if ($media-queries) {
    $bp-large-screen: 1824px;
    $bp-bigscreen: 1824px;
    $bp-ipad-max: 1024px;
    $bp-ipad-min: 768px;
    $bp-iphone5-max: 568px;
    $bp-iphone-max: 480px;
    $bp-iphone-min: 320px;
    // **
    // --------------------
    // Options of screen breakpoints:
    // 1. large-screen - from 1824px
    // 2. big-screen - to 1824px
    // 3. ipad-mini - to 758px
    // 4. ipad - from 758px to 1024px
    // 5. ipad-portrait - to 758px
    // 6. iphone5-landscape - to 568px
    // 7. iphone - from 320px to 480px
    // 8. iphone-portrait - to 320px
    // --------------------
    // **
    @if $point == large-screen {
      @media // Very Large Screens
      only screen and (min-width: #{$bp-large-screen}),
      only screen and (min-device-width: #{$bp-large-screen}) {
        @content;
      }
    }
    @if $point == big-screen {
      @media // Desktops and Laptops
      only screen and (max-width: #{$bp-bigscreen}),
      only screen and (max-device-width: #{$bp-bigscreen}) {
        @content;
      }
    }
    @if $point == ipad-mini {
      @media // iPad-Mini (portrait and landscape)
      handheld,
      only screen and (min-device-pixel-ratio: 1),
      only screen and (max-width: #{$bp-ipad-min}){
        @content;
      }
    }
    @if $point == ipad {
      @media // iPad (portrait and landscape)
      handheld,
      only screen and (max-width: #{$bp-ipad-max})
                  and (min-width: #{$bp-iphone5-max}) {
        @content;
      }
    }
    @if $point == ipad-portrait {
      @media // iPad (portrait)
      handheld,
      only screen and (min-device-pixel-ratio: 2),
      only screen and (max-width: #{$bp-ipad-min})
                  and (orientation: portrait) {
        @content;
      }
    }
    @if $point == iphone5-landscape {
      @media // iPhone 5 (landscape)
      handheld,
      only screen and (min-device-pixel-ratio: 2),
      only screen and (max-width: #{$bp-iphone5-max}) {
        @content;
      }
    }
    @if $point == iphone {
      @media // iPhone (portrait and landscape)
      handheld,
      only screen and (min-device-pixel-ratio: 2),
      only screen and (max-width: #{$bp-iphone-max})
                  and (min-width: #{$bp-iphone-min}) {
        @content;
      }
    }
    @if $point == iphone-portrait {
      @media // iPhone (portrait)
      handheld,
      only screen and (min-device-pixel-ratio: 2),
      only screen and (max-width: #{$bp-iphone-min}) {
        @content;
      }
    }
  }
}
