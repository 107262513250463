.the-analytics {
    .secondary-header-cont {
        width: 100%;
        height: 150px;
        background-color: #fff;
        position: relative;
        top: -55px;

        .range-picker {
            margin-top: 88px;
            margin-left: 20px;
        }
    }

    .analysis-chart {
        margin-top: -55px;
    }
}