.chatmain {
  background-color: #fff;
  .messagedetail {
    position: relative;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
    height: 80px;
    padding-bottom: 8px;
    padding-left: 8px;
    .useravatar {
      margin-left: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-top: 6px;
      @media (max-width: 768px) {
        margin-left: 10px;
      }
    }
    .right {
      // padding-left: 10px;
      // padding-top: 10px;
      // justify-content: center;
      // align-items: center;
      margin-left: 10px;
      margin-top: 6px;
    }
  }

  .chat {
    background-color: #fff;
    // min-height: 300px;
    min-height: calc(100vh - 244px);
    max-height: calc(100vh - 244px);
    overflow-y: scroll;
    padding: 30px;

    p {
      // padding: 10px;
      // margin: 30px;
      margin-bottom: 0;
      color: #333;
      word-break: break-all;
    }
  }
  .message-sentbox {
    position: relative;
    padding: 0 20px;
    z-index: 10;
    box-shadow: 0px -2px 0 rgba(0, 0, 0, 0.03);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .chat-message-box {
      display: flex;
      width: 100%;
    }
    
    .attachment {
      // cursor: pointer;
      // width: 60px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-left: -13px;
      position: relative;
      top: 10px;
    }
    .typefield {
      width: 100%;
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 6px;
      }
      // input[type='text'] {
      //   background-color: #ececec;
      //   border-radius: 15px;
      //   padding-left: 30px;
      //   padding-right: 30px;
      //   height: 40px;
      //   width: 100%;
      //   font-size: 14px;
      //   border: #e2e2e2;
      //   outline: none;
      //   color: #333;
      // }
    }
    input[type='file'] {
      display: none;
    }
    .sent-button {
      justify-content: space-between;
      border: none;
      color: #ffffff;
      background-color: #15558d;
      min-width: 46px;
      min-height: 46px;
      border-radius: 50%;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .sent-button {
      justify-content: center;
      border: none;
      color: #ffffff;
      background-color: #15558d;
      min-width: 46px;
      min-height: 46px;
      border-radius: 50%;
      outline: none;
    }
    .paper-clip {
      display: flex; 
      align-items: center;
      justify-content: center;
      // height: 10px;
      // width: 24px;
      // font-size: 30px;
      cursor: pointer;
    }
  }
}
.leftside-chat {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  .msgbox {
    background: rgb(77, 125, 242);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width: 60%;
    padding: 10px;
    border-radius: 15px 0px 15px 15px !important;
  }
}

.rightside-chat {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .msgbox {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 4px;
    color: #29303b;
    max-width: 60%;
    border-radius: 0px 15px 15px 15px !important ;
    background-color: #ffffff;
    padding: 10px;
  }
}

// .download-img-icon {
//   // padding: 5px;
//   cursor: pointer;
//   &:hover {
//     background-color: #d1cdcd !important;
//   }
// }

.send-time {
  font-size: 13px;
  margin-bottom: 20px;
}

.chat-date-preview {
  text-align: center;
}