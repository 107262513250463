.tutorilSection {
    margin-top: -30px;
    .videoBlock {
        .videoCard  {
            margin-bottom: 20px;
            .videoDesc {
                min-height: 110px;
                .descContent {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 10px;
                    p {
                        span {
                            padding: 0 7px;
                            border-radius: 3px;
                            color: #fff!important;
                        }
                    }
                }
            }
        }

        .sectionTwo {
            margin-bottom: 20px;
            .videoDesc {
                min-height: 60px;
            }
        }
    }
}

.tutorialModal {
.modalInnercontent {
    .modalDesc {
        padding-top: 10px;
        span {
            padding: 0 7px;
    border-radius: 3px;
    color: #fff !important;
        }
    }
}
}

.tutorilsBlock {
    .sub-header-cont {
        height: 120px;
        width: 100%;
        background-color: #fff;
        position: relative;
        top: -55px;
    }
}