.dashboard {
  .table-content-box {
    margin-top: 50px;
    h2 {
      @include head;
      @include breakpoint(xs) {
        font-size: 28px;
      }
    }
    .table-content {
      .headerButton {
        background: $bg-white;
        padding: 20px;
        border-bottom: 1px solid #f1f1f1;
      }
      .ant-table-thead {
        .ant-table-cell {
          background: $bg-white;
        }
      }
    }
  }
  .chartBlock {
    padding-right: 20px;
    padding-top: 20px;
    height: 355px;
    @media (max-width: 991px) {
      padding-right: 0;
      margin-bottom: 50px;
    }
    @media (min-width: $ipad-portrait) and (max-width: $ipad-landscape) {
      margin-bottom: 50px;
    }
    .chartInner {
      padding-top: 40px;
    }
  }
  .countBlock {
    margin-top: 60px;
    margin-bottom: -30px;
    .stats-calendar {
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-end;
      @include breakpoint(xs) {
        flex-direction: column;
        span {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.card-section {
  .ant-list {
    .ant-list-item {
      margin-bottom: 25px;

      .ant-card {
        padding: 0px !important;
        @include border-radius(6px);

        .ant-card-body {
          @include flex(center, center);

          flex: 1;
          box-shadow: 0 8px 15px #dcdfe6;
          padding: 0px !important;
          min-height: 50px;
          .inline-card-item {
            width: 100%;
            flex: 1;
            @include flex(flex-start, center);
            .count-square-bg {
              border-bottom-left-radius: 5px;
              border-top-left-radius: 5px;
              width: 60px;
              min-width: 60px;
              height: 55px;
              // @include border-radius(100px);
              background-color: rgb(66, 165, 246);
              @include flex(center, center);
              color: $white;
              margin-right: 10px;
              font-weight: 600;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

// this will setting the cart size
canvas {
  height: 300px !important;
}
