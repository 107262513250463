
body {
  font-family: 'Roboto', sans-serif;
  color: $textcolor;

  .pending {
    color: #d83810;
  }
  .mb0 {
    margin-bottom: 0;
  }
  .white {
    color: $white;
  }
  .bold {
    font-weight: 600;
  }
  img {
    max-width: 100%;
  }
 .w-100 {
    width: 100%;
  }
  .m-auto {
    margin:0 auto;
  }
  .failed {
    color: #fc3503;
  }

  .paid {
    color: #26ad00;
  }

  .requested {
    color: #032cfc;
  }

  .manually_paid {
    color: #26ad00;
  }

  .pending {
    color: #d83810;
  }
  .confirmed {
    color: #26ad00;
  }
  .cancelled {
    color: #d83810;
  }
  .completed {
    color: #26ad00;
  }
  .textRight {
    text-align: right
  }

  .circular-square {
    @include circular--square;
  }
  .lead {
    font-size: 1.2rem !important;
  }
  .d-block {
    display: block;
  }
  .icon-button {
    cursor: pointer;
   i {
     font-size: 15px;
   }

  }
  .plus-button {
    @include plus-button;
  }
  a,
  p,
  td {
    font-size: 13px;
    color: $textcolor;
  }
  .delete-color {
    color: #e43e42;
  }
  .edit-color {
    color: rgb(24, 85, 141);
  }
  .view-color {
    color: #1a568b;
  }
  .success {
    color: $success;
  }
  .edit-button {
    @include edit-button;
  }

  .inactive-button {
    background-color: #a8a8a8;
    border: none;
    @include button;
  }

  .delete-button {
    @include delete-button;
  }
  .saveButton {
    @include saveButton;
  }
  .btnTransparent {
    @include btnTransparent;
    .edit-button {
      background-color: rgb(76, 119, 160) !important;
      padding: 5px;
      margin-left: 5px!important;
    }
  }
  .view-button {
    @include view-button;
  }
  .accept-button {
    @include accept-button;
  }
  .d-flex {
    @include flex(space-between, center);
  }
  .text-center {
    @include text-center;
  }
  .blueDark-button {
    @include blueDark-button;
  }
  .ant-table-thead .ant-table-cell {
    color: rgb(120, 129, 149);
    background-color: rgb(241, 243, 246);
    border-bottom: 0px;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }
  .form-control {
    @include formInput;
  }
  .selectBox {
    @include selectBox;
  }

  .fullwidth {
    width: $full-width;
  }
  .fullwidth-right {
    @include flex(flex-end, flex-end);
  }
  .pageTitle {
    background: #fff;
    padding: 20px;
    margin-bottom: 1rem;
    h4 {
      margin-bottom: 0;
    }
  }
  .signUpForm {
    padding: 20px;
  }
  .ant-modal-content {
    @include border-radius(10px);
    .ant-modal-header {
      padding: 13px 16px;
      background: rgb(250, 250, 250);
      color: rgb(50, 51, 50);
      border-bottom: 1px solid rgb(233, 233, 233);
      border-radius: 4px 4px 0px 0px;
      .ant-modal-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
      }
    }

    .ant-modal-body {
      form {
        background: transparent !important;
        .title {
          @include flex(start, center);
          margin-top: 30px;
          input:focus {
            outline: none;
          }
          span {
            display: inline-block;
            margin-left: 15px;
          }
        }
      }
    }
    .mt-5 {
      margin-top: 30px;
    }
  }
  .ant-pagination {
    .ant-pagination-disabled {
      .ant-pagination-item-link {
        color: #333;
        background-color: #dedede;
      }
    }

    .ant-pagination-item-active {
      background: #15558d;
      border-color: #15558d;
      &:hover {
        background-color: #20c0f3;
        border-color: #20c0f3;
      }
      a {
        color: #fff !important;
      }
    }
    .ant-pagination-item {
      a {
        color: #9a9a9a;
      }
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
    color: #7d7d7d;
  }
  // .ant-select:not(.ant-select-customize-input) {
  //   .ant-select-selector {
  //     border: 1px solid #d9d9d9;
  //   }
  // }
  .MuiInput-root {
    width: 100%;
  }
  .holdActive {
    min-width:5.5rem;
  }
.whiteBox {
  background:$white;
  padding: 25px;
}
  .container {
    width: 100%;
    display: flex;
    align-self: center;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include breakpoint(xs) {
    .container {
      flex-direction: column;
    }
  }
  @include breakpoint(sm) {
    .container {
      max-width: 540px;
      // flex-direction: column;
    }
  }
  @include breakpoint(m) {
    .container {
      max-width: 720px;

    }
  }

  @include breakpoint(l) {
    .container {
      max-width: 960px;
    }
  }
  @include breakpoint(xl) {
    .container {
      max-width: 1140px;
    }
  }




}
.matBorder {
  color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
}
.select-underline {
  position: relative;
// &::before {
//   left: 0;
//     right: 0;
//     bottom: 0;
//     content: "\00a0";
//     position: absolute;
//     transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.42);
//     pointer-events: none;
//     &:hover {
//       border-bottom: 2px solid rgba(0, 0, 0, 0.87);
//      }
//   }
//     &::after {
//       left: 0;
//       right: 0;
//       bottom: 0;
//       content: "";
//       position: absolute;
//       transform: scaleX(0);
//       transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
//       border-bottom: 2px solid #3f51b5;
//       pointer-events: none;
//     }
//     .ant-select:not(.ant-select-customize-input),
//     .ant-select-selector {
//       border-bottom: 1px solid #d9d9d9!important;
//       border-radius: 0!important;
//       border-left: none!important;
//       border-right: none!important;
//       border-top: none!important;
//     }
.ant-select:not(.ant-select-customize-input), .ant-select-selector {
  border: 1px solid transparent!important;
    border-bottom: 1px solid #d9d9d9!important;
    border-radius: 0!important;
  }
}
@include breakpoint(xs) {
  .buttonInline {
    margin-top: 30px;
  }
}

