.loginWrapper{
    background-color: #f4f6f7;
    height: 100vh;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    @include flex(center, center);
    .login-page {
width: 30%;
padding:50px;
background-color: $bg-white;
border-radius: 10px;
box-shadow: 0 0 20px 0 #f2f2f2;
border: 1px solid #efefef;
@include border-radius(10px);

@include breakpoint(xs) {
    padding: 25px;
    width: 100%;
}

h1 {
    margin-bottom: 35px;
}
.edit-button {
    margin-top:35px;
    background-color: rgb(24, 85, 141) !important;
}
.loginInput{
    div {
        width:$full-width;
        margin-bottom: 20px;
    }
}
}
}
@media (max-width:$ipad-small) {
    .login-page {
        width: 100%!important;
        h2 {
            font-size: 25px;
        }
}
}
