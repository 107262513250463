.onlinebookWrapper {
  background-color: #f4f6f7;
  // height: 100vh;
  width: 100%;
  @include flex(center, center);
  .login-page {
    width: 80%;
    padding: 50px;
    background-color: $bg-white;
    @include border-radius(3px);
    min-height: 100vh;
    @include breakpoint(xs) {
      padding: 20px;
    }
    h1 {
      margin-bottom: 35px;
    }
    .edit-button {
      background-color: rgb(24, 85, 141) !important;
      margin-top: 35px;
    }
    .loginInput {
      div {
        width: $full-width;
        margin-bottom: 20px;
      }
    }
    .ant-divider-horizontal {
      margin: 0;
    }
    .officesList {
      padding: 10px;
      @include breakpoint(xs) {
        @include flex(start, center);
        .hospitalName b {
        font-size: 14px!important;
        }
      }
    }
    @include breakpoint(xs) {
      .ant-carousel {
      .slick-prev {
        left: -15px!important;
    }
    .slick-next {
      right: -25px!important;
  }
  }
    }
    @include breakpoint(xs) {
    .dateSlide.pl4 {
padding-left: 0;
    }
  }

  }
  .smallText {
    font-size: 14px;
    color: #7b7b7b;
  }
  .datebold {
    font-size: 15px;
    font-weight: 600;
    @include breakpoint(xs) {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .availDate {
    .slick-slide {
      width: 125px;
  }
  .ant-carousel {
    .slick-next {
      right: 0;
    }
  }
  }
  .typeAppointment {
    background-color: rgb(237, 238, 238);
    font-weight: 500;
    color: black;
    padding:10px;
    border-radius: 3px;
    cursor: pointer;
  }
  .dropselectBox {
    background-color: #EDEEEE;
    text-align: center;
    border-radius: 5px;
    padding: 7px 5px;
  }
}

.timing:hover {
  background-color: #00cbe6;
}

.ant-menu-vertical {
  border: 1px solid #f0f0f0;
  .ant-menu-item {
    border-bottom: 0;
  }
}


.ant-modal-confirm-body > .anticon-check-circle {
  display: none !important;
}