.breadcrumb {
  margin: 16px;
  .breadcrumb-nav {
    height: 30px;
    line-height: 30px;
    margin-bottom: 16px;
    background: #f8f8f8;
  }
  .breadcrumb-nav a {
    line-height: 30px;
    padding: 0 8px;
  }
  .app-list {
    margin-top: 16px;
  }
}
