.appointment-section{
    .top-bar-items {
        position: relative;
        bottom: -35px;
    }
    .appointment-table-section {
        position: relative;
        top: -60px;
    }
    .search {
        // @include flex(flex-end , flex-end);
        // margin-bottom: 15px;

        padding: 50px 15px 70px 15px;
        // padding-bottom: -20px;
        position: relative;
        top: -60px;
        background: $bg-white;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23);

        .filter-btn {
            position: relative;
            top: 40px;
        }
    }
    .title-name {
        font-weight: 600;
        font-size: 14px;
    }
    .iconAppt {
        i {
            width: 20px;
        }
       
    }
    .right-side{
        @include flex(center, center)
    }
    
}

.appointment-view {
    p {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: .5rem;
       
    }
    p.title {
        font-weight: 600;
        text-transform: capitalize;
        border-bottom: 1px solid #f9f9f9;
        background-color: #f5f5f5;
        padding: 5px;
        margin-bottom: 1rem;
        color:#333;
    }
    .responseBox {
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        .responseBg {
            background: #f5f5f5;
            padding: 10px;
            border-bottom: 1px solid #e4e4e4;
            .question {
                font-weight: 600;
            }
        }
    }
}

.proSearchmbl {
   .ant-picker {
       width: 150px;
   }
}

.inlineFilter {
    .buttonInline {
        position: relative;
        top: 10px;
@include flex(flex-end, center);
    }

    .ant-input {
        font-size: 13px;
        height: 35px;
    }
    .ant-picker-input {
        height: 25px;
        input {
        font-size: 13px;
        }
    }
    .ant-select-selection-item {
        font-size: 13px;
    }
    // .ant-select-selector {
    //     height: 35px;
    // }
    .ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
            height: 35px;
        }
    } 
}