.the-campains {
  .textRight {
    width: 100%;
    background-color: #fff;
    height: 120px;
    position: relative;
    top: -55px;
  }
  .campaignTabs {
    margin-top: -50px;
  }
}

.ant-tabs {
  // .ant-tabs-tab.ant-tabs-tab-active {
  //   .ant-tabs-tab-btn {
  //     padding: 0 30px;
  //     font-size: 16px;
  //   color: #2be0fd;
  // }
  .ant-tabs-tab-btn {
    font-weight: 500;
    color: #15558d;
    padding: 0 30px;
  }
}
.newCampaign {
  .boxWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    .box {
      border: 1px solid #fafafa;
      margin: 5px;
      width: 20%;
      flex-basis: 150px;
      flex-grow: 1;
      @media (min-width:768px) and (max-width:991px) {
        min-width: 300px;
        max-width: 325px;
        height: 290px;
        flex-basis: auto;
      }
      .boxInner {
        height: 100%;
        padding: 10px;
        @include border-radius(5px);
        .tickIcon {
          display: flex;
          justify-content: flex-end;
          height: 40px;
          font-size: 20px;
          .activeClr {
            color: #109400;
          }
        }
        .text-center {
          .MuiInputLabel-animated {
            font-size: 14px;
          }
          .MuiInputBase-input {
            font-size: 13px;
          }
          .iconRound {
            width: 50px;
            height: 50px;
            @include border-radius(50%);
            background: #e0e0e0;
            margin: auto;
            margin-bottom: 25px;
            @include transit;
            @include flex(center, center);
            i {
              font-size: 25px;
              color: #ffffff;
            }
            &:hover {
              background: #038fdf;
            }
          }
        }
      }
    }
  }
  .view-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 40px;
  }
}

.smsEditmodalCampaign {
  .mobileBg {
    height: 450px;
    position: relative;
    @include breakpoint(xs) {
      margin-bottom: 25px;
    }
    .mobile-background {
      background-repeat: no-repeat;
      height: 100%;
      background-position: center center;
      position: relative;
      .textareaHight {
        display: block;
        min-height: 360px;
        textarea {
          position: absolute;
          right: 0px;
          top: 48px;
          left: 70px;
          width: 58%;
          height: 338px!important;
          resize: none;
          border: none;
          outline: none;
          @include breakpoint(xs) {
            width: 60%;
            left: 60px;
            height: 340px!important;
          }
          @media (min-width: $ipad-portrait) and (max-width: $ipad-landscape) {
            left: 65px;
          }
        }
      }
      .msgLength {
        position: absolute;
        right: 202px;
      }
    }
  }
  .modalRight {
    height: 100%;
    .collapseContent {
      min-height: 60%;
    }
    .mbltextBtn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
    .ant-collapse-item {
      .ant-collapse-content-box {
        background: #fafafa;
      }
    }
    .ant-collapse-item-active {
      background: #048fdf;
      .ant-collapse-header {
        color: $white;
        .ant-collapse-arrow {
          svg {
            color: $white;
          }
        }
      }
    }
  }
}
.overlay {
  pointer-events: none;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  opacity: 0.5;
  background: #ececec;
  border-radius: 10px;
  margin-top: 10px;
}
.overlay-with-out-efffect{
  margin-top: 10px;

}
.campaignWrapper {
  .headerTop {
    border-bottom: 1px solid #d9d9d9;
    @include breakpoint(xs) {
      padding-bottom: 15px;
    }

    .rightPosition {
      @include flex(flex-end, start);
    }
    .MuiFormControl-marginNormal {
      margin-top: 0;
      margin-bottom: 20px;
      .MuiInput-underline:before {
        border-bottom: 1px solid #ececec;
      }
    }
  }
  .tabSection {
    .tabBox {
      width: 100%;
      padding-top: 18px;
      @include breakpoint(xs) {
        width: 100%;
      }
      .ant-tabs-nav {
        &:before {
          border-bottom: transparent;
        }
        .ant-tabs-nav-list {
          border-bottom: 1px solid #f5f5f5;
        }
      }
    }
  }
}
.remindersEmail {
  .switchCampain {
    @include flex(flex-end, center);
  }
  .editorPanel {
    .mce-panel {
      background: #f5f9ff;
      border: 0 solid #e3ebf7;
      .mce-btn {
        background-color: #dae6f7;
      }
      .mce-statusbar {
        background: #fff;
      }
      .mce-container {
        #mceu_35-body {
          button {
            width: 85px;
          }
        }
      }
    }
  }
  .campaignAccordion {
    .ant-collapse {
      .ant-collapse-content {
        border-top: 1px solid #eaeaea;
      }
      .ant-collapse-item {
        border-bottom: 1px solid #eaeaea;
      }
    }
  }
}
@include breakpoint(xs) {
  .mce-menubtn.mce-fixed-width {
    span {
      width: 60px!important;
    }
  }

  .mce-container *,
  .mce-widget {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 12px!important;
    white-space: normal!important;
  }
}
@media (min-width: $ipad-portrait) and (max-width: $ipad-landscape) {
  .campaignWrapper {
    .headerTop {
      padding-bottom: 10px;
    }
    .tabSection {
      .tabBox {
        width: 75%;
      }
    }
  }
.campaignTabs {
  .ant-tabs-tab {
    margin: 0 10px 0 0;
    .ant-tabs-tab-btn {
      padding: 0 20px;
    }
  }
}


}
@media(min-width:992px) {
  .modalRight {
    padding-left: 50px;
  }
}
