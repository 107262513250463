.ant-modal-mask {
  background-color: rgba(22, 22, 22, 0.5);
  backdrop-filter: blur(10px);
}

.the-branches {
  .create-branch {
    background-color: #fff;
    width: 100%;
    height: 120px;
    position: relative;
    top: -55px;
  }

  .table-content {
    margin-top: -55px;
  }
}

.header {
  button {
    @include common-button;
  }
}
.branch_address {
  &:hover {
    border-bottom: 2px solid #3f51b5 !important;
  }
  &:focus,
  &:active,
  &:focus-within {
    border-bottom: 2px solid #3f51b5;
  }
}

.table-content {
  margin-top: 50px;
}
.MuiFormControl-root {
  width: 100%;
}
.holdActive {
  min-width:5.5rem;
}